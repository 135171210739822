import React from 'react';

import { Form } from '../form';
import { PlayersSearch } from './PlayersSearch';

const Example = () => {
  return (
    <Form defaultValues={{}} onSubmit={() => {}}>
      <PlayersSearch id="Example__players" name="players" title="Players" />
    </Form>
  );
};

export default Example;
